import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { UqCustomerStory, UqTypography, UqContainerV2, UqLink, UqPage, UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqCustomerProfile, UqRequestDemoBanner } from '@uq-components';
import Logo from '@uq-assets/logos/cargurus.svg';

export default function CarGurusPage() {
  return (
    <UqPage
      config={{
        seo: {
          description: 'Why CarGurus chose unitQ “to understand customer feedback across various customer segments”',
          src: '/images/v2/customers/cargurus/cargurus-hero.jpg',
          title: 'Cargurus Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header backgroundSrc="/images/v2/customers/cargurus/cargurus-hero.jpg">
            <UqTypography as="h1">
              {'Why CarGurus chose unitQ “to understand customer feedback across various customer segments”'}
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">Automotive Research & Shopping</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">Cambridge, Massachusett</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">1,200+</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">
                It was difficult to decipher insights quickly from customer
                user-feedback data because it was fragmented and difficult to parse.
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Conclusion">
                unitQ enables a holistic view of user feedback data,
                helping CarGurus refine their product offerings and better meet the needs of customers.
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>
              <p>
                Christina Valente, Senior Director of Product Operations at CarGurus, leverages her
                Product Operations expertise to empower a team of global product managers with deep, actionable customer insights.
                Understanding customer feedback and addressing user pain points effectively is not just a nice to have,
                but a cornerstone of CarGurus&apos; product strategy.
              </p>
              <p>
                <span>
                  “Customer insights is one of our primary pillars,” Christina said during a recent panel discussion at the {' '}
                </span>
                <UqLink
                  className="article-link"
                  to="https://www.unitq.com/blog/unitq-director-of-product-brett-caplan-speaking-at-2024-CPO-summit/"
                  external
                  hideArrow
                >
                  Chief Product Officer Summit
                </UqLink>
                <span>
                  {' '} in New York.
                </span>
              </p>
              <p>
                The challenge of understanding customer insights at CarGurus is multifaceted.
                With a myriad of feedback coming from various channels such as app reviews, NPS surveys, Salesforce and more,
                it became difficult to decipher insights quickly from a single source of truth as customer
                data has been fragmented and difficult to parse, Christina said.
                The absence of a common taxonomy to classify feedback alongside the complexity of data sources,
                made it difficult for product managers to draw actionable insights in real-time.
              </p>
              <p>
                <span>
                  It was clear, Christina said, that CarGurus, the most visited automotive shopping site in the United States {'('}
                </span>
                <UqLink
                  className="article-link"
                  to="https://finance.yahoo.com/quote/CARG/?.tsrc=fin-srch"
                  external
                  hideArrow
                >
                  CARG
                </UqLink>
                <span>
                  {')'}, was in need of a scalable user-feedback solution.
                </span>
              </p>

              <UqTypography as="h4">
                Buy versus build !-approach-!
              </UqTypography>

              <p>
                <span>
                  The inability to efficiently glean actionable insights from
                  user feedback led to the pivotal decision to integrate unitQ into CX,
                  product and engineering workflows at CarGurus.
                  Christina and her team recognized that building a custom solution from scratch was
                  impractical as it would set them back by months and require internal resources.
                  Instead, CarGurus opted for a {''}
                </span>
                <UqLink
                  className="article-link"
                  to="https://www.unitq.com/blog/build-vs-buy-unitq-is-right-choice-for-ai-powered-user-feedback/"
                  external
                  hideArrow
                >
                  buy versus build approach
                </UqLink>
                <span>, choosing unitQ for its robust capabilities and native integrations into existing software and tools.</span>
              </p>

              <UqTypography as="h4">
                A riddling !-introduction-!
              </UqTypography>

              <p>
                <span>
                  Before Christina spoke, unitQ Director of Product Brett Caplan introduced her with a two-part riddle to prime the crowd.
                  He asked, “What&apos;s black and white and red all over?”
                </span>
                <br />
                <span>
                  Someone from the audience answered: “Is it a newspaper?”
                </span>
                <br />
                <span>
                  “No, an embarrassed penguin,” Brett replied.
                </span>
                <br />
                <span>
                  After the laughter, Brett asked a similar question:
                </span>
                <br />
                <span>
                  “So now, what&apos;s black and white and mostly unread?”
                </span>
                <br />
                <span>
                  Many from the audience jokingly answered:
                </span>
                <br />
                <span>
                  “A newspaper?”
                </span>
                <br />
                <span>
                  “I was thinking of most user feedback — a little more thematic to what we’re here today for,”
                  Brett continued, handing the discussion to Christina.
                </span>
              </p>

              <UqTypography as="h4">
                Out-of-the-box !-integrations-!
              </UqTypography>

              <p>
                Christina said that unitQ not only supported the complexity of the CarGurus marketplace
                but also allowed her team to dissect and understand customer feedback across various customer segments.
              </p>

              <p>
                <span>“unitQ has out-of-the box </span>
                <UqLink
                  className="article-link"
                  to="https://www.unitq.com/integrations/"
                  external
                  hideArrow
                >
                  integrations
                </UqLink>
                <span>
                  {' '} for about 80% of our data sources,
                  and the other 20% they committed to building. unitQ is able to support the complexity of our marketplace,” she said.
                </span>
              </p>

              <UqTypography as="h4">
                Land and !-expand-!
              </UqTypography>

              <p>
                Christina said that, as the adoption of unitQ progressed at CarGurus, it became apparent that the unitQ customer
                feedback analytics platform could serve broader purposes beyond initial expectations.
              </p>

              <p>
                “As I started having conversations with key stakeholders across the organization about unitQ,
                we started to explore some additional use cases that we&apos;d be able to use for unitQ,” she said.
              </p>

              <p>
                <span>
                  Christina said unitQ has empowered internal discussions around customer
                  experiences not just within product teams but across sales,{' '}
                </span>
                <UqLink
                  className="article-link"
                  to="https://www.unitq.com/solutions/support/"
                  external
                  hideArrow
                >
                  support
                </UqLink>
                <span> and{' '}</span>
                <UqLink
                  className="article-link"
                  to="https://www.unitq.com/solutions/marketing/"
                  external
                  hideArrow
                >
                  marketing
                </UqLink>
                <span> departments — aligning all teams around common customer issues.</span>
              </p>

              <UqTypography as="h4">
                CarGurus !-conclusions-!
              </UqTypography>

              <p>
                The introduction of unitQ at CarGurus under Christina&apos;s leadership is
                transforming how CarGurus approaches customer feedback.
                It enables a holistic view that quantifies customer sentiment and satisfaction,
                helping CarGurus refine their product offerings and better meet the needs of customers.
                The journey with unitQ at the helm of their customer insight strategy is just beginning.
                unitQ&apos;s potential to drive significant improvements in customer satisfaction
                and operational efficiency is immense, Christina said.
              </p>

              <StaticImage
                src="../../images/customers/cargurus/cargurus-quote.jpg"
                alt="cargurus conclusions"
                placeholder="dominantColor"
                height={520}
              />
            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />

      </UqLayoutV2>
    </UqPage>
  );
}
